import React from "react"
import styled from "styled-components"

export const Answer = styled.li`
  font-size: 1.3rem;
  padding: 2rem 2.5rem 2rem 7.5rem;
  position: relative;
  font-weight: normal;
  list-style: none;
  border-bottom: 1px solid #f1f1f1;
  margin: 0;

  span {
    display: inline-block;
  }

  em {
    font-style: normal;
    font-weight: normal;
    background-color: #f1f1f1;
    border-radius: 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    left: 20px;
    top: 10px;
  }

  ${props =>
    props.isSelected &&
    `
    background-color: #fcdcdd;
    em { background-color: white; color: #f76076; font-weight: bold; }
  `} ${props =>
    props.isCorrect &&
    `
    background-color: #a4e5db;
    font-weight: bold;
    em { color: #25c1a9; background-color: white; font-weight: bold; }
  `};
`

export const ResponseMessage = styled.p`
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 1.5rem 1.5rem;
  display: block;
  margin: 0;
  border-bottom: 1px solid white;
  font-weight: bold;
  letter-spacing: 1px;

  ${props =>
    props.answeredCorrectly
      ? `
  background-color: #a4e5db;
  color: #25c1a9;`
      : `
  background-color: #fbd5d5;
  color: #ed6364;
`};
`
