import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const ModalContainer = styled.div`
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  z-index: ${zIndex.modal};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: #2b2e38;
  background: rgba(43, 46, 56, 0.9);
  overflow-y: scroll;
  transition: all ease-in 0.2s;
`

export const ModalInner = styled.div`
  text-align: center;
  background: white;
  position: relative;
  padding: 3rem;
  margin: 3rem auto;
  z-index: ${zIndex.modal};
  border: 7px solid ${props => props.theme.colors.primary};
  width: 90%;
  left: 5%;
  position: absolute;
  transition: all ease-in 0.2s;

  h2 {
    font-size: 2rem;
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
  }

  img, svg {
    margin: 0 auto;
    max-height: 15rem;
  }

  @media ${md} {
    margin: 0 auto;
    max-width: 60rem;
    left: 50%;
    width: 60%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    h2 {
      font-size: 2.6rem;
    }
    img, svg {
      max-height: 250px;
    }
    p {
      font-size: 1.8rem;
    }
  }
`
